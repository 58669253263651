import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  DataGrid,
  GridSortModel,
  GridFilterModel,
  GridSelectionModel,
} from '@mui/x-data-grid';
import Can from 'Components/Atoms/AccessControl/Can';
import LinkBehavior from 'Components/Atoms/LinkBehavior';
import useConfirmDelete from 'Components/Hooks/ConfirmDelete';
import useLoading from 'Components/Hooks/Loading';
import useLocaleDataGrid from 'Components/Hooks/LocaleDataGrid';
import { useNotify } from 'Components/Hooks/Notify';
import useTranslation from 'Components/Hooks/Translate';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { timeZoneFormatter } from 'Utils/string';

import NoReadPermission from './NoReadPermission';
import { SettingsTableConfig } from './SettingsConfig';

export type SettingsTableParams = { tableId: string; itemId?: string };

// 設定の一覧画面のコンポーネント

const SettingsTable = (props: {
  items: any[];
  isLoading: boolean;
  table: SettingsTableConfig;
}) => {
  // const [listLoading, setListLoading] = useState(false);
  // const [items, setItems] = useState<any[]>([]);
  // const [countAll, setCountAll] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { load } = useLoading();

  // const isFirstTime = useRef(true);
  // const refreshCounter = useRef(0);
  const navigate = useNavigate();
  const notify = useNotify();
  const t = useTranslation('Settings');
  const localeDataGrid = useLocaleDataGrid();
  const confirmDelete = useConfirmDelete();

  // const refresh = useCallback(async () => {
  //   setItems([]);
  //   setListLoading(true);
  //   refreshCounter.current++;
  //   const currentRefreshCounter = refreshCounter.current;
  //   const response = await props.table.getList({
  //     range: {
  //       from: pageSize * page,
  //       to: pageSize * (page + 1) - 1,
  //     },
  //   });
  //   if (currentRefreshCounter === refreshCounter.current) {
  //     setItems(response.items);
  //     setCountAll(response.count);
  //     setListLoading(false);
  //   }
  //   return response;
  // }, [page, pageSize, props.table]);

  const deleteSelectedItems = async () => {
    if (await confirmDelete()) {
      // refreshCounter.current++;
      // const currentRefreshCounter = refreshCounter.current;
      await load(
        async () =>
          await props.table.deleteItems(selectionModel.map((x) => x.toString()))
      );
      // if (currentRefreshCounter === refreshCounter.current) {
      //   refresh();
      // }
      notify(t('common.deleteSuccess'), 'success');
    }
  };

  // useEffect(() => {
  //   return () => {
  //     refreshCounter.current = refreshCounter.current + 1;
  //   };
  // }, []);

  // useEffect(() => {
  //   refresh();
  // }, [refresh, props.table]);

  // サーバーサイドページネーションはとりあえず実装省略
  // useEffect(() => {
  //   // table用のuseEffectと被るので初回は無視
  //   if (isFirstTime.current) {
  //     isFirstTime.current = false;
  //   } else {
  //     (async () => {
  //       if (props.table.serverSidePagination === true) {
  //       await refresh();
  //       }
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortModel, filterModel, page, pageSize]);

  const paginationMode = 'client';
  // props.table.serverSidePagination === true ? 'server' : 'client';
  return (
    <>
      <Box
        sx={{
          height: '100%',
          p: 4,
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          gap: 2,
        }}
      >
        <Stack spacing={2} direction="row" justifyContent={'flex-end'}>
          <Typography variant="h2" sx={{ mr: 'auto', alignSelf: 'center' }}>
            {props.table.displayName}
          </Typography>
          <Can crud="create" componentName={props.table.resourceName}>
            <Button
              variant="shadow"
              startIcon={<LibraryAddOutlinedIcon />}
              component={LinkBehavior}
              href={props.table.name + '/create'}
            >
              {t('common.createNew')}
            </Button>
          </Can>
          <Can crud="delete" componentName={props.table.resourceName}>
            <Button
              startIcon={<DeleteOutlinedIcon />}
              disabled={
                selectionModel.length === 0 &&
                (props.table.canDeleteItems == null ||
                  props.table.canDeleteItems(
                    selectionModel.map((x) => x.toString())
                  ))
              }
              onClick={() => deleteSelectedItems()}
              color="warning"
            >
              {t('common.deleteSelected')}
            </Button>
          </Can>
        </Stack>

        <Can
          crud="read"
          componentName={props.table.resourceName}
          no={<NoReadPermission displayName={props.table.displayName} />}
        >
          <DataGrid
            checkboxSelection
            disableSelectionOnClick
            loading={props.isLoading}
            rows={props.items}
            columns={props.table.columns.map((x) => {
              const { link, ...columnsProps } = x;
              return {
                ...columnsProps,
                valueFormatter: (v) => {
                  if (columnsProps.type === 'date') {
                    return timeZoneFormatter(v.value, 'yyyy/MM/DD');
                  } else if (columnsProps.type === 'dateTime') {
                    return timeZoneFormatter(v.value);
                  } else return v.value;
                },
                ...(link
                  ? {
                      renderCell:
                        x.link == null
                          ? undefined
                          : (params) => (
                              <Link
                                to={x.link != null ? x.link(params.row) : ''}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {params.value}
                              </Link>
                            ),
                    }
                  : {}),
              };
            })}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            //ページネーション設定
            pagination
            paginationMode={paginationMode}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            // rowCount={countAll}
            sortingMode={paginationMode}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            filterMode={paginationMode}
            onFilterModelChange={setFilterModel}
            filterModel={filterModel}
            getRowId={props.table.getRowId}
            disableColumnSelector //列表示メニューは非表示
            localeText={localeDataGrid}
            onRowClick={(event) =>
              navigate(props.table.name + '/' + event.id.toString())
            }
            sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
          />
        </Can>
      </Box>
    </>
  );
};

export default SettingsTable;
