const sound = new Audio('/assets/ring_tone.mp3');

export const ring = (boolean: boolean) => {
  if (boolean) {
    sound.play();
    sound.loop = true;
  } else {
    sound.pause();
    sound.currentTime = 0;
    sound.loop = false;
  }
};
