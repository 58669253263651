import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import useContactReasons, {
  useDeleteContactReasons,
  useInsertContactReason,
  useUpdateContactReason,
} from 'Components/Hooks/Queries/ContactReasons';
import useTranslation from 'Components/Hooks/Translate';
import { SettingsTableConfig } from 'Components/Organisms/Settings/SettingsConfig';
import SettingsTable from 'Components/Organisms/Settings/SettingsTable';
import { useMemo, useCallback } from 'react';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import { components } from 'Utils/Permission';
import supabase, {
  ContactReason,
  ContactReasonCategory,
  Job,
} from 'Utils/supabase';

import ContactReasonForm from './ContactReasonForm';

const ContactReasonTable = (props: { table: SettingsTableConfig }) => {
  const result = useContactReasons(undefined, { refetchOnWindowFocus: false });
  if (result.isError) {
    throw new AppError(
      result.error,
      'Sys',
      'SettingsContactReason',
      'ReadMany',
      '01'
    );
  }

  return (
    <SettingsTable
      table={props.table}
      items={result.data ?? []}
      isLoading={result.isFetching}
    />
  );
};

const ContactReasonBreadcrumb = (props: { id: string }) => (
  <>
    {useContactReasons(undefined, {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.id)?.name ?? ''}
  </>
);

const useContactReasonsConfig: () => SettingsTableConfig = () => {
  const t = useTranslation('Settings');
  const insertItem = useInsertContactReason();
  const updateItem = useUpdateContactReason();
  const deleteItems = useDeleteContactReasons();
  const loadToItem = useCallback(async (item: any) => {
    // ジョブ一覧取得
    const jobsResult = await supabase.from<Job>('jobs').select().order('name');
    if (jobsResult.error != null) {
      throw new AppError(
        jobsResult.error,
        'Sys',
        'SettingsContactReason',
        'ReadOne',
        '03'
      );
    }
    item.jobs = jobsResult.data.map((x) => ({
      id: x.id,
      displayName: x.name,
    }));

    // カテゴリ一覧取得
    const categoriesResult = await supabase
      .from<ContactReasonCategory>('contact_reason_categories')
      .select()
      .order('name');
    if (categoriesResult.error != null) {
      throw new AppError(
        categoriesResult.error,
        'Sys',
        'SettingsContactReason',
        'ReadOne',
        '04'
      );
    }
    item.categories = categoriesResult.data.map((x) => ({
      id: x.id,
      displayName: x.name,
      jobId: x.jobId,
    }));
  }, []);

  return useMemo<SettingsTableConfig>(
    () => ({
      name: 'contact-reasons',
      resourceName: components.ContactReason,
      displayName: t('contact_reasons.name'),
      singleName: t('contact_reasons.nameSingle'),
      icon: <SpeakerNotesOutlinedIcon />,
      columns: [
        {
          field: 'name',
          headerName: t('contact_reasons.field.name'),
          flex: 1,
          minWidth: 200,
        },
        {
          field: 'jobs',
          headerName: t('contact_reasons.field.job'),
          width: 200,
          valueGetter: (params) => params.value.name,
          link: (item: any) => `jobs/${item.jobId}`,
        },
        {
          field: 'category',
          headerName: t('contact_reasons.field.contactReasonCategory'),
          width: 200,
          valueGetter: (params) => params.value.name,
          link: (item: any) =>
            `contact-reason-categories/${item.contactReasonCategoryId}`,
        },
        {
          field: 'createdAt',
          headerName: t('common.field.createdAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
        {
          field: 'updatedAt',
          headerName: t('common.field.modifiedAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
      ],
      getItem: async (id) => {
        const result = await supabase
          .from<ContactReason>('contact_reasons')
          .select()
          .eq('id', id)
          .maybeSingle();
        if (result.error != null) {
          throw new AppError(
            result.error,
            'Sys',
            'SettingsContactReason',
            'ReadOne',
            '01'
          );
        }
        if (result.data == null) {
          throw new AppError(
            result.error,
            'Biz',
            'SettingsContactReason',
            'ReadOne',
            '02'
          );
        }
        await loadToItem(result.data);
        return result.data;
      },
      getDefault: async () => {
        const item = { name: '' };
        await loadToItem(item);
        return item;
      },
      renderList: (table) => <ContactReasonTable table={table} />,
      renderItem: (isNew, item, form) => (
        <ContactReasonForm isNew={isNew} item={item} form={form} />
      ),
      renderBreadcrumb: (id) => <ContactReasonBreadcrumb id={id} />,
      validation: i18nYup.object({
        name: i18nYup
          .string()
          .label(t('contact_reasons.field.name'))
          .required()
          .max(255),
        jobId: i18nYup
          .string()
          .label(t('contact_reasons.field.job'))
          .required(),
        contactReasonCategoryId: i18nYup
          .string()
          .label(t('contact_reasons.field.contactReasonCategory'))
          .required(),
      }),
      insertItem: async (item: any) => {
        await insertItem.mutateAsync(item);
      },
      updateItem: async (item: any) => {
        await updateItem.mutateAsync(item);
      },
      deleteItems: async (items: any[]) => {
        await deleteItems.mutateAsync(items);
      },
    }),
    [t, loadToItem, insertItem, updateItem, deleteItems]
  );
};

export default useContactReasonsConfig;
