import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import useLocaleDataGrid from 'Components/Hooks/LocaleDataGrid';
import useTranslation from 'Components/Hooks/Translate';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { timeZoneFormatter } from 'Utils/string';
import supabase from 'Utils/supabase';
import { definitions } from 'Utils/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
type sessionHistories = definitions['session_histories'] & {
  jobName: { name: string };
  operatorName: { name: string };
};

// クエリは外出し
const useSessionHistories = (params: {
  rangeFrom: number;
  rangeTo: number;
  sortField: string;
  sortIsAsc: boolean;
}) => {
  const keys = [
    'session_histories',
    params.rangeFrom,
    params.rangeTo,
    params.sortField,
    params.sortIsAsc,
  ];
  return useQuery(
    keys,
    async () => {
      //ソートフィールドは、オペレーターとジョブに関してはリレーション先の名前で行う必要があるため面倒
      const data = await supabase
        .from<sessionHistories>('session_histories')
        .select(
          `
        *,
        jobName:jobs (name),
        operatorName:user_profiles!session_histories_operatorId_fkey (name)
        `,
          { count: 'exact' }
        )
        .range(params.rangeFrom, params.rangeTo)
        .order(params.sortField as any, { ascending: params.sortIsAsc });
      if (data.error) throw data.error;
      return { data: data.data, count: data.count ?? undefined };
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 10 * 1000,
    }
  );
};

const SessionHistories = () => {
  const t = useTranslation('Session');
  const localeDataGrid = useLocaleDataGrid();
  const navigate = useNavigate();

  const rowsPerPage = 100;
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<GridSortModel | undefined>();

  const query = useSessionHistories({
    rangeFrom: page * rowsPerPage,
    rangeTo: (page + 1) * rowsPerPage,
    sortField: sort?.[0]?.field ?? 'startedAt',
    sortIsAsc: sort?.[0]?.sort === 'asc',
  });

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (query.data?.count != null) {
      setCount(query.data?.count);
    }
  }, [query.data?.count]);

  const [data, setData] = useState<sessionHistories[]>([]);
  useEffect(() => {
    if (query.data?.data != null) {
      setData(query.data?.data);
    }
  }, [query.data?.data]);

  return (
    <>
      <Box sx={{ p: 4, height: '100%' }}>
        <DataGrid
          columns={[
            {
              field: 'operatorName',
              headerName: t('histories.operator'),
              width: 200,
              valueGetter: (x) => x.value?.name ?? '',
              sortable: false,
            },
            // {
            //   field: 'userId',
            //   headerName: t('histories.user'),
            //   width: 200,
            // },
            {
              field: 'jobName',
              headerName: t('histories.job'),
              width: 250,
              valueGetter: (x) => x.value?.name ?? '',
              sortable: false,
            },
            {
              field: 'videoState',
              headerName: t('histories.videoState'),
              width: 70,
              renderCell: (params) => (
                <ConvertCircularProgress value={params.value} />
              ),
            },
            {
              field: 'shortCode',
              headerName: t('histories.shortCode'),
              width: 120,
            },
            {
              field: 'startedAt',
              headerName: t('histories.start'),
              type: 'dateTime',
              valueGetter: (params) => new Date(params.value),
              valueFormatter: (params) => {
                return timeZoneFormatter(params.value);
              },
              width: 170,
            },
            {
              field: 'endedAt',
              headerName: t('histories.end'),
              type: 'dateTime',
              valueGetter: (params) => new Date(params.value),
              valueFormatter: (params) => {
                return timeZoneFormatter(params.value);
              },
              width: 170,
            },
          ]}
          rows={data ?? []}
          getRowId={(x) => x.iid}
          loading={query.isFetching}
          onRowClick={(event) => {
            // if (event.row.videoState === 'Succeed') {
            navigate('/session-histories/' + event.id.toString());
            // }
          }}
          localeText={localeDataGrid}
          //
          // ---------- サーバーページネーション関連の設定 ----------
          rowCount={count}
          // ページあたりの表示件数はとりあえず固定
          rowsPerPageOptions={[rowsPerPage]}
          // フィルターは複雑なのでとりあえず無効化
          disableColumnFilter
          // ページネーション
          paginationMode="server"
          page={page}
          onPageChange={setPage}
          // ソート設定
          sortingMode="server"
          onSortModelChange={setSort}
          sortModel={sort}
        />
      </Box>
    </>
  );
};
export default SessionHistories;

const ConvertCircularProgress = (props: { value: string }) => {
  if (props.value === 'Succeed') {
    return <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />;
  } else if (props.value === 'Error') {
    return <ErrorOutlineOutlinedIcon color="error" fontSize="large" />;
  } else if (props.value == null) {
    return null;
  } else if (props.value.startsWith('Converting ')) {
    const percent = Number(props.value.split(' ')[1].split('%')[0]);
    if (isNaN(percent)) {
      return null;
    } else {
      return (
        <CircularProgressWithLabel
          value={percent}
          sx={{ ml: '2px' }}
          size="30px"
        />
      );
    }
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ ml: '4px' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
