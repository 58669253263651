import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Stack, Typography } from '@mui/material';
import useConfirm from 'Components/Hooks/Confirm';
import useJobs, {
  useDeleteJobs,
  useInsertJob,
  useUpdateJob,
} from 'Components/Hooks/Queries/Jobs';
import useTranslation from 'Components/Hooks/Translate';
import { SettingsTableConfig } from 'Components/Organisms/Settings/SettingsConfig';
import SettingsTable from 'Components/Organisms/Settings/SettingsTable';
import { useCallback, useMemo } from 'react';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import { components } from 'Utils/Permission';
import supabase, { Job, JobsUser, UserProfile } from 'Utils/supabase';

import JobForm from './JobForm';

const JobTable = (props: { table: SettingsTableConfig }) => {
  const result = useJobs(undefined, { refetchOnWindowFocus: false });
  if (result.isError) {
    throw new AppError(result.error, 'Sys', 'SettingsJob', 'ReadMany', '01');
  }

  return (
    <SettingsTable
      table={props.table}
      items={result.data ?? []}
      isLoading={result.isFetching}
    />
  );
};

const JobBreadcrumb = (props: { id: string }) => (
  <>
    {useJobs(undefined, {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.id)?.name ?? ''}
  </>
);

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const useJobsConfig: () => SettingsTableConfig = () => {
  const t = useTranslation('Settings');
  const insertItem = useInsertJob();
  const updateItem = useUpdateJob();
  const deleteItems = useDeleteJobs();
  const confirm = useConfirm();

  const loadToItem = useCallback(async (item: any) => {
    // ユーザー一覧取得
    const userProfilesResult = await supabase
      .from<UserProfile>('user_profiles')
      .select()
      .order('name');
    if (userProfilesResult.error != null) {
      throw new AppError(
        userProfilesResult.error,
        'Sys',
        'SettingsJob',
        'ReadOne',
        '03'
      );
    }
    item.userProfiles = userProfilesResult.data.map((x) => ({
      id: x.id,
      displayName: x.name,
    }));

    // ジョブに紐づくユーザー一覧取得
    if (item.id != null) {
      const jobUsersResult = await supabase
        .from<JobsUser>('jobs_users')
        .select()
        .eq('jobId', item.id);
      if (jobUsersResult.error != null) {
        throw new AppError(
          jobUsersResult.error,
          'Sys',
          'SettingsJob',
          'ReadOne',
          '04'
        );
      }
      item.jobUserIds = jobUsersResult.data.map((x) => x.userId);
    }

    // 重複チェック用
    const jobsResult = await supabase.from<Job>('jobs').select();
    if (jobsResult.error != null) {
      throw new AppError(
        jobsResult.error,
        'Sys',
        'SettingsJob',
        'ReadOne',
        '05'
      );
    }
    item.jobs = jobsResult.data;
    item.useTelephoneLink = (item.telephoneNumber ?? '') !== '';
  }, []);

  return useMemo<SettingsTableConfig>(
    () => ({
      name: 'jobs',
      resourceName: components.Job,
      displayName: t('jobs.name'),
      singleName: t('jobs.nameSingle'),
      icon: <SummarizeOutlinedIcon />,
      columns: [
        {
          field: 'name',
          headerName: t('jobs.field.name'),
          flex: 1,
          minWidth: 200,
        },
        {
          field: 'createdAt',
          headerName: t('common.field.createdAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
        {
          field: 'updatedAt',
          headerName: t('common.field.modifiedAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
      ],
      getItem: async (id) => {
        const result = await supabase
          .from<Job>('jobs')
          .select()
          .eq('id', id)
          .maybeSingle();
        if (result.error != null) {
          throw new AppError(
            result.error,
            'Sys',
            'SettingsJob',
            'ReadOne',
            '01'
          );
        }
        if (result.data == null) {
          throw new AppError(
            `Not found job id: ${id}`,
            'Biz',
            'SettingsJob',
            'ReadOne',
            '02'
          );
        }
        await loadToItem(result.data);
        return result.data;
      },
      getDefault: async () => {
        const item = {
          name: '',
          jobUserIds: [],
          useWebCall: true,
          useVideo: true,
          useAudio: true,
          useCobrowse: true,
          canChangeUseVideo: true,
          canChangeUseAudio: true,
          canChangeUseCobrowse: true,
          telephoneNumber: '',
          includeShorCodeInLink: false,
          telephoneUrl: '',
          enableVideoRecording: false,
          supportFrame: false,
          supportCanvas: 0,
        };
        await loadToItem(item);
        return item;
      },
      renderList: (table) => <JobTable table={table} />,
      renderItem: (isNew, item, form) => (
        <JobForm isNew={isNew} item={item} form={form} />
      ),
      renderBreadcrumb: (id) => <JobBreadcrumb id={id} />,
      validation: i18nYup.object({
        name: i18nYup
          .string()
          .label(t('jobs.field.name'))
          .required()
          .max(255)
          .test({
            message: t('jobs.message.duplicatedName'),
            test: async (value, obj) => {
              // 名前の重複チェック(ローカルに保持しているものと比較)
              const id = obj.parent.id as string | undefined;
              const name = (obj.parent.name as string) ?? '';
              const jobs = obj.parent.jobs as Job[];
              return (
                jobs.find(
                  (x) => x.name === name && (id == null || x.id !== id)
                ) == null
              );
              // 以下の方法でその都度サーバーから取得可能であるが、1文字ごとに検証されてしまうので取りやめ
              // return await loading(async () => {
              //   const response = await supabase
              //     .from<Job>('jobs')
              //     .select()
              //     .match({ name: name });

              //   if (response.error != null) {
              //     console.log(response.error);
              //     return false; // エラー時はとりあえず重複扱い
              //   }
              //   if ((response.data ?? []).length === 0) {
              //     return true; // データが取れてなければ重複無し
              //   }
              //   if (response.data[0].id === id) {
              //     return true; // 自分自身であれば重複無し
              //   }
              //   return false;
              // });
            },
          }),
        // チェックが有効な時のみバリデーション
        telephoneNumber: i18nYup.string().when('useTelephoneLink', {
          is: true,
          then: i18nYup
            .string()
            .required()
            .max(255)
            .matches(phoneRegExp, t('jobs.message.invalidTelephoneNumber')),
        }),
        telephoneUrl: i18nYup
          .string()
          .label(t('jobs.field.telephoneUrl'))
          .max(255),
      }),

      insertItem: async (item: any) => {
        const { primaryKey, secondaryKey } = await insertItem.mutateAsync(item);
        // キーダイアログを表示する。(ロード中表示を終了させるため、awaitはしない)
        confirm({
          caption: t('jobs.message.newKey'),
          message: (
            <Stack spacing={2}>
              <Typography variant="h3">{t('jobs.message.primary')}</Typography>
              <Typography>{primaryKey}</Typography>
              <Typography variant="h3">
                {t('jobs.message.secondary')}
              </Typography>
              <Typography>{secondaryKey}</Typography>
            </Stack>
          ),
          dialogProps: {
            maxWidth: 'md',
          },
        });
      },
      updateItem: async (item: any) => {
        await updateItem.mutateAsync(item);
      },
      deleteItems: async (items: any[]) => {
        await deleteItems.mutateAsync(items);
      },
    }),
    [t, confirm, loadToItem, insertItem, updateItem, deleteItems]
  );
};

export default useJobsConfig;
