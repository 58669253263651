import { Dialog } from '@mui/material';
import DialogTitleWithClose from 'Components/Atoms/DialogTitleWithClose';
import useConfirmDelete from 'Components/Hooks/ConfirmDelete';
import useLoading from 'Components/Hooks/Loading';
import { useNotify } from 'Components/Hooks/Notify';
import useTranslation from 'Components/Hooks/Translate';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SettingsTableConfig } from './SettingsConfig';
import SettingsItemForm from './SettingsItemForm';

// 設定フォームの外側部分のコンポーネント

const SettingsItem = (props: { table: SettingsTableConfig }) => {
  const navigate = useNavigate();
  const { load } = useLoading();

  const [item, setItem] = useState<any>();
  const [open, setOpen] = useState(false);
  const refreshCounter = useRef(0);

  const params = useParams<{ settingItemId?: string }>();
  const isNew = params.settingItemId === 'create';
  const notify = useNotify();

  const t = useTranslation('Settings');
  const confirmDelete = useConfirmDelete();

  const refresh = async () => {
    if (params.settingItemId == null) {
      setOpen(false);
    } else {
      setItem(undefined);

      refreshCounter.current++;
      const currentRefreshCounter = refreshCounter.current;
      try {
        const response = await load(async () =>
          isNew
            ? await props.table.getDefault()
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              await props.table.getItem(params.settingItemId!)
        );
        if (currentRefreshCounter === refreshCounter.current) {
          setItem(response);
          setOpen(true);
        }
        return response;
      } catch (e) {
        navigate(props.table.name);
        throw e;
      }
    }
  };

  const saveItem = async (item: any) => {
    if (isNew) {
      await load(async () => await props.table.insertItem(item));
    } else {
      await load(async () => await props.table.updateItem(item));
    }
    navigate(props.table.name);
    notify(t('common.saveSuccess'), 'success');
  };

  const deleteItem = async (itemId: string) => {
    if (await confirmDelete()) {
      await load(async () => await props.table.deleteItems([itemId]));
      navigate(props.table.name);
      notify(t('common.deleteSuccess'), 'success');
    }
  };

  useEffect(() => {
    refresh();
    return () => {
      refreshCounter.current = refreshCounter.current + 1;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.settingItemId]);

  const itemIsNew = (props.table.getRowId?.(item) ?? item?.id) == null;

  return (
    <Dialog open={open} maxWidth={props.table.dialogWidth ?? 'sm'}>
      <DialogTitleWithClose onClose={() => navigate(props.table.name)}>
        {props.table.singleName}
      </DialogTitleWithClose>
      <SettingsItemForm
        isNew={itemIsNew}
        item={item}
        table={props.table}
        onSave={saveItem}
        onCancel={() => navigate(props.table.name)}
        onDelete={itemIsNew ? undefined : deleteItem}
      />
    </Dialog>
  );
};

export default SettingsItem;
