import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import {
  useDeleteRoles,
  useInsertRole,
  useRoles,
  useUpdateRole,
} from 'Components/Hooks/Queries/Roles';
import useTranslation from 'Components/Hooks/Translate';
import { SettingsTableConfig } from 'Components/Organisms/Settings/SettingsConfig';
import SettingsTable from 'Components/Organisms/Settings/SettingsTable';
import { useMemo, useCallback } from 'react';
import { AppError } from 'Utils/error';
import i18nYup from 'Utils/i18nYup';
import { components } from 'Utils/Permission';
import supabase, { Role, RolePermision } from 'Utils/supabase';

import RoleForm from './RoleForm';

const RoleTable = (props: { table: SettingsTableConfig }) => {
  const result = useRoles({ refetchOnWindowFocus: false });
  if (result.isError) {
    throw new AppError(result.error, 'Sys', 'SettingsRole', 'ReadMany', '01');
  }

  return (
    <SettingsTable
      table={props.table}
      items={result.data ?? []}
      isLoading={result.isFetching}
    />
  );
};

const RoleBreadcrumb = (props: { id: string }) => (
  <>
    {useRoles({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }).data?.find((x) => x.id === props.id)?.roleName ?? ''}
  </>
);

const useRolesConfig: () => SettingsTableConfig = () => {
  const t = useTranslation('Settings');
  const insertItem = useInsertRole();
  const updateItem = useUpdateRole();
  const deleteItems = useDeleteRoles();
  const loadToItem = useCallback(async (item: any) => {
    // ロールに紐づく権限一覧取得
    if (item.id != null) {
      const rolePermResult = await supabase
        .from<RolePermision>('role_permissions')
        .select()
        .eq('roleId', item.id);
      if (rolePermResult.error != null) {
        throw new AppError(
          rolePermResult.error,
          'Sys',
          'SettingsRole',
          'ReadOne',
          '03'
        );
      }
      item.rolePermIds = rolePermResult.data.map((x) => x.permissionId);
    }

    // 重複チェック用
    const rolesResult = await supabase.from<Role>('roles').select();
    if (rolesResult.error != null) {
      throw new AppError(
        rolesResult.error,
        'Sys',
        'SettingsRole',
        'ReadOne',
        '04'
      );
    }
    item.roles = rolesResult.data;
  }, []);

  return useMemo<SettingsTableConfig>(
    () => ({
      name: 'roles',
      resourceName: components.Role,
      displayName: t('roles.name'),
      singleName: t('roles.nameSingle'),
      icon: <GroupsOutlinedIcon />,
      dialogWidth: 'md',
      columns: [
        {
          field: 'roleName',
          headerName: t('roles.field.name'),
          flex: 1,
        },
        {
          field: 'createdAt',
          headerName: t('common.field.createdAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
        {
          field: 'updatedAt',
          headerName: t('common.field.modifiedAt'),
          type: 'date',
          valueGetter: (params) => new Date(params.value),
          width: 100,
        },
      ],
      getItem: async (id) => {
        const result = await supabase
          .from<Role>('roles')
          .select()
          .eq('id', id)
          .maybeSingle();
        if (result.error != null) {
          throw new AppError(
            result.error,
            'Sys',
            'SettingsRole',
            'ReadOne',
            '01'
          );
        }
        if (result.data == null) {
          throw new AppError(
            `Not found role id: ${id}`,
            'Biz',
            'SettingsRole',
            'ReadOne',
            '02'
          );
        }
        await loadToItem(result.data);
        return result.data;
      },
      getDefault: async () => {
        const item = { name: '', rolePermIds: [] };
        await loadToItem(item);
        return item;
      },
      renderList: (table) => <RoleTable table={table} />,
      renderItem: (isNew, item, form) => (
        <RoleForm isNew={isNew} item={item} form={form} />
      ),
      renderBreadcrumb: (id) => <RoleBreadcrumb id={id} />,
      validation: i18nYup.object({
        roleName: i18nYup
          .string()
          .label(t('roles.field.name'))
          .required()
          .max(255),
      }),
      insertItem: async (item: any) => {
        await insertItem.mutateAsync(item);
      },
      updateItem: async (item: any) => {
        await updateItem.mutateAsync(item);
      },
      deleteItems: async (items: any[]) => {
        await deleteItems.mutateAsync(items);
      },
    }),
    [t, loadToItem, insertItem, updateItem, deleteItems]
  );
};

export default useRolesConfig;
